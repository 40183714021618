.account {
  padding-left: 1rem;
}

.accordionSummary {
  z-index: 3;
  background-color: var(--colorSecondary);
  border-top: 0.0625rem solid var(--colorSecondaryHover);
}

.accordionSummary:first-child {
  border-top: none;
}

.accordionSummary > div {
  align-items: center;
}

.accordionSummary > div > div:first-child {
  cursor: pointer;
  min-width: 17.125rem;
}
.accordionSummary i {
  font-weight: 375;
  font-size: 0.8rem;
}

.expandable {
  transition: height ease 0.5s;
  height: 0;
  overflow: hidden;
  /* border-bottom: 0.0625rem solid var(--colorSecondaryHover); */
}

.payable:has(> *:first-child),
.receivable:has(> *:first-child),
.driver:has(> *:first-child) {
  height: 16.5rem;
}

.vendor:has(> *:first-child) {
  height: 30rem;
}

.stop:has(> *:first-child) {
  height: 56rem;
}

.stop:has(* > .createLocation) {
  height: 64rem;
}

.stop:has(* > .addLumper) {
  height: 62rem;
}

.stop:has(* > .addLumper):has(* > .createLocation) {
  height: 70rem;
}

.contact:has(> *:first-child) {
  height: 20.5rem;
}

.submit {
  width: 17.125rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
