.pageContent {
  position: relative;
  max-width: 100vw;
  width: 100vw;
  background-color: var(--colorPrimary);
  display: flex;
  flex-direction: row;
  position: relative;
}

.content {
  height: calc(100vh - 5.125rem);
  width: 100%;
  padding: 0 2rem 2rem 2rem;
  overflow-y: auto;
}

.subHeader {
  display: flex;
  justify-content: flex-end;
  height: 3rem;
}

.themeToggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.themeToggle button {
  background: none;
  border: none;
}
