.summaryRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 2rem;
  top: 0;
  border-bottom: 0.125rem solid var(--colorSecondary);
  margin-bottom: 0.5rem;
}

.summaryRow > div {
  margin: 0.4rem 0rem;
}

.summaryLabel {
  font-weight: bold;
  margin-right: 0.2rem;
}

.downloadButton {
  display: flex;
  align-items: center;
}
