
*:has(.createNoteHolder) .btnHolder {
  border-bottom: 0.0625rem solid var(--colorSecondary);
}

*:has(.createNoteHolder:empty) .btnHolder {
  border-bottom: 0.0625rem solid var(--colorSecondaryHover);
}

.btnHolder {
  position: sticky;
  background-color: var(--colorSecondary);
  z-index: 4;
  display: flex;
  top: 0;
}

.btnHolder svg {
  padding: 0 0.25rem;
}

.btnHolder button {
  padding: 0.5rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  height: inherit;
  display: flex;
  align-items: center;
}

.createNoteHolder {
  z-index: 3;
  position: sticky;
  background-color: var(--colorSecondary);
  transition: height ease 0.5s;
  height: 0;
  overflow: hidden;
}

.createNoteHolder:has(> *:first-child) {
  height: 10rem;
  border-bottom: 0.0625rem solid var(--colorSecondaryHover);
}