.input,
.select,
.textarea {
  color: var(--colorFont);
  background-color: var(--colorPrimary);
  border-color: var(--colorSecondary);
  padding: 0.3rem;
  border-width: 0.125rem;
  border-style: inset;
  font-size: var(--fontStandard);
}
.textarea {
  field-sizing: content;
}

.input[type='file'] {
  padding: 0.125rem;
}

.input[type='file'] {
  width: unset;
}

.input {
  width: 16.125rem;
}

.input[type='checkbox'] {
  width: unset;
}

.input[name='lumperButton'] {
  display: none;
}

/* GoogleSearchBox has location for name */
/* Applying width by giving specific IDs */
.input[id='location'] {
  width: 36rem;
}

.select {
  width: 16.975rem;
  padding: 0.2375rem;
}

.input[type='date'] {
  padding: 0.213rem 0.3rem;
}

.input[type='time'] {
  padding: 0.158rem 0.3rem;
}

.input:disabled,
.input[readonly],
.textarea:disabled,
.textarea[readonly],
.select:disabled {
  background-color: var(--colorDisabled);
  opacity: 1;
}

.select option:disabled {
  display: none;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
