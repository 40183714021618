.arApplyPaymentForm {
  position: sticky;
  display: flex;
  flex-direction: column;
  border-top: 0.125rem solid var(--colorSecondary);
  border-bottom: 0.125rem solid var(--colorSecondary);
  background-color: var(--colorPrimary);
  z-index: 1;
  padding-bottom: 0.5rem;
}
.arApplyPaymentForm h4,
.arApplyPaymentForm h5 {
  margin: 0.2rem 0 0.4rem;
}

.arApplyPaymentForm > span {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  gap: 1rem;
}

.arApplyPaymentForm label {
  font-size: small;
}

.arApplyPaymentForm button {
  margin: 0 0 0.5rem;
  align-self: flex-end;
  flex-wrap: wrap;
}

.arApplyPaymentForm .idSection {
  display: flex;
  gap: 1rem;
}

.divider {
  width: 1px; 
  background-color: #ccc; 
  margin: 0 0.2rem;
}
