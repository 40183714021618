.overlay {
  display: none;
}

.modalContainer:has(.modalHolder > *:first-child) .overlay {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--colorOverlay);
  overflow: hidden;
  animation: fadeIn 0.25s linear;
  z-index: 11;
}

.modalHolder > * {
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fadeInSolid 0.01s ease-in;
  z-index: 12;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: fit-content;
  height: fit-content;
  max-height: 32rem;
  max-width: 100vw;
  box-shadow: rgb(0 0 0 / 60%) 0 1.6rem 2.5rem 0,
    rgb(0 0 0 / 50%) 0 0.3rem 0.9rem 0;
  background-color: var(--colorSecondary);
  animation: fadeInSolid 0.01s ease-in;
  padding: 1rem;
}

.modal > :first-child {
  max-height: 28rem;
  overflow-y: auto;
}

.modal h3 {
  text-align: center;
}

.modal td,
.modal th {
  border: 0.0625rem solid var(--colorSecondaryHover);
}

.modal > button:last-child {
  margin-top: 1rem;
  margin-bottom: 0;
  min-height: fit-content;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: var(--colorOverlay);
  }
}

@keyframes fadeInSolid {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.yesNoModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35rem;
  height: 15rem;
  max-width: 100vw;
  box-shadow: rgb(0 0 0 / 60%) 0 1.6rem 2.5rem 0,
    rgb(0 0 0 / 50%) 0 0.3rem 0.9rem 0;
  background-color: var(--colorSecondary);
  animation: fadeInSolid 0.01s ease-in;
  justify-content: space-around;
}

.yesNoModal h3 {
  width: 90%;
  text-align: center;
  font-size: 1.25rem;
}

.buttonHolder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3.5rem;
}
