*:has(.expandable) .btnHolder {
  border-bottom: 0.0625rem solid var(--colorSecondary);
}

*:has(.expandable:empty) .btnHolder {
  border-bottom: 0.0625rem solid var(--colorSecondaryHover);
}

.btnHolder {
  background-color: var(--colorSecondary);
  z-index: 4;
  display: flex;
  top: 0;
}

.btnHolder svg {
  padding: 0 0.25rem;
}

.btnHolder button {
  padding: 0.5rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  height: inherit;
  display: flex;
  align-items: center;
}

.expandable {
  z-index: 3;
  background-color: var(--colorSecondary);
  transition: height ease 0.5s;
  height: 0;
  overflow: hidden;
}

.commodity:has(> *:first-child) {
  height: 10rem;
}
.vendor:has(> *:first-child) {
  height: 19.7rem;
}

.service:has(> *:first-child) {
  height: 10rem;
}

.driver:has(> *:first-child) {
  height: 16.5rem;
}

.lane:has(> *:first-child) {
  height: 9.5rem;
}

.stop:has(> *:first-child) {
  height: 56rem;
}

.stop:has(* > .createLocation) {
  height: 64rem;
}

.createRateHolder {
  z-index: 3;
  background-color: var(--colorSecondary);
  transition: height ease 0.5s;
  height: 0;
  overflow: hidden;
}

.createRateHolder:has(> *:first-child) {
  height: 10rem;
  border-bottom: 0.0625rem solid var(--colorSecondaryHover);
}
