.boardContainer {
  overflow-x: auto;
  max-height: 200rem;
  transition: max-height 0.4s ease-in-out;
}

.boardContainer table {
  width: unset;
  min-width: 100%;
}

.boardContainer th {
  position: sticky;
  top: -1px; /* Prevents visual clipping of table content above the table head */
  background-color: var(--colorCTAAccent);
}

.boardContainer th,
.boardContainer td {
  white-space: nowrap;
}

.iconContainer {
  width: 6rem;
  display: flex;
  justify-content: space-evenly;
}

.title {
  display: flex;
  flex-direction: row;
  font-size: var(--fontMedium);
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  height: 20px;
}

.title svg {
  color: var(--colorFont);
  font-size: var(--fontLarge);
  cursor: pointer;
}

.title .tableActive {
  color: var(--colorCTA);
}

.tablePartial {
  max-height: 14rem;
}

.tableMin {
  max-height: 0;
  overflow: hidden;
}
