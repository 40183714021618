.sidebar {
  top: 3.125rem;
  left: 0;
  height: calc(100vh - 3.125rem);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--colorSecondary);
  width: 11.5rem;
  min-width: 11.5rem;
  font-size: var(--fontMedium);
}

.sidebar,
.dropDown > * {
  transition: all 0.25s, background 0s;
}

.sidebar a,
.dropDownOptions > div,
.dropDownOptionsActive > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 2rem;
  background-color: var(--colorSecondary);
}

.toggleHamburger {
  height: 2rem;
  min-height: 2rem;
  display: flex;
  align-items: center;
  z-index: 3;
}

.sidebar a:hover,
.dropDownOptions > div:hover,
.toggleHamburger:hover {
  background-color: var(--colorSecondaryHover);
}

.toggleHamburger div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar svg {
  min-width: 2.5rem;
  color: var(--colorFont);
}

.sidebar a div,
.sidebar label div {
  min-width: 9rem;
}

.sidebar input {
  display: none;
}

.sidebar:has(> input:first-child:checked) {
  width: 2.5rem;
  min-width: 2.5rem;
}

.sidebar:has(> input:first-child:checked) * > div:nth-child(2) {
  display: none;
}

.activeLink {
  border-left: inset 0.25rem var(--colorCTA);
}

.activeLink > svg {
  margin-left: -0.25rem;
}

.toggleArrow svg {
  transform: rotate(0) translateY(15%);
  transition: transform 0.4s;
}

.toggleArrowActive svg {
  transform: rotate(-180deg) translateY(15%);
  transition: transform 0.4s;
}

.sidebar:has(> input:first-child:not(:checked)) .dropDownOptions > div,
.sidebar:has(> input:first-child:not(:checked)) .dropDownOptions > a {
  display: none;
}

.dropDownOptionsActive svg,
.dropDownOptions svg {
  opacity: 0;
}

.sidebar:has(> input:first-child:checked) .dropDownOptions > div {
  display: relative;
}

.sidebar:has(> input:first-child:checked) .dropDown:hover > div {
  position: absolute;
  transform: translate(2.5rem, -2rem);
  display: block;
  z-index: 5;
}

.sidebar:has(> input:first-child:checked) .dropDown:hover * > div:nth-child(2) {
  display: block;
}

.sidebar:has(> input:first-child:checked) .dropDown:hover > label {
  background-color: var(--colorSecondaryHover);
}

.sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--colorDisabled);
}

.sidebar::-webkit-scrollbar {
  width: 3px;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: var(--colorFont);
}
