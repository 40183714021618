.button {
  background-color: var(--colorSecondaryHover);
  border: none;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:disabled {
  opacity: 0.7;
  background-color: var(--colorSecondaryHover);
}
