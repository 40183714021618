.subGrid {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 0.5rem;
}

.subGrid input {
  margin-top: 0.2rem;
  display: flex;
  flex-direction: column;
  max-width: fit-content;
}
