*:has(.formHolder) .btnHolder {
  border-bottom: 0.0625rem solid var(--colorSecondary);
}

*:has(.formHolder:empty) .btnHolder {
  border-bottom: 0.0625rem solid var(--colorSecondaryHover);
}

.btnHolder {
  position: sticky;
  background-color: var(--colorSecondary);
  z-index: 4;
  display: flex;
  justify-content: space-between;
  top: 0;
}

.btnHolder svg {
  padding: 0 0.25rem;
}

.btnHolder button {
  padding: 0.5rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  height: inherit;
  display: flex;
  align-items: center;
}

.formHolder {
  z-index: 3;
  background-color: var(--colorSecondary);
  transition: height ease 0.5s;
  height: 0;
  overflow: hidden;
}

.formHolder:has(> *:first-child) {
  height: fit-content;
  border-bottom: 0.0625rem solid var(--colorSecondaryHover);
}

.filesModalHolder {
  background-color: var(--colorSecondary);
  width: 60rem;
}
