.mapContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mapContainer:has(canvas) {
  position: relative;
  height: 22rem;
}

.mapContainer button {
  width: fit-content;
}

.buttonContainer {
  display: flex;
  gap: 1rem;
  padding-top: .5rem;
  border-top: 0.0625rem solid var(--colorSecondaryHover);
}
