.container.container {
  width: unset;
}

.container > input {
  color: var(--colorFont);
  background-color: var(--colorPrimary);
  border-color: var(--colorSecondary);
  padding: 0.3rem;
  border-width: 0.125rem;
  border-style: inset;
  width: 16.125rem;
}

.fileTableContainer {
  margin: 0rem 0rem 1rem;
}

.fileTableContainer.fileTableContainer table td,
.fileTableContainer.fileTableContainer table th {
  border: none;
  text-align: left;
}

.fileTableContainer label {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.fileTableContainer tr:not(thead tr):hover {
  background-color: var(--colorSecondaryHover);
}

.fileTableCheckbox {
  display: flex;
  place-content: center;
  border: none;
  cursor: pointer;
}

.fileTableContainer table {
  border-collapse: collapse;
  border: none;
  max-width: 50%;
  min-width: fit-content;
}

.fileTableContainer table td:has(input[type='checkbox']) {
  max-width: fit-content;
  padding-right: 0.5rem;
}

.fileTableContainer table td:not(:has(input[type='checkbox'])) {
  padding-right: 2rem;
  min-width: 4rem;
}

.fileTableContainer td,
.fileTableContainer th {
  padding: 0;
  text-align: left;
  border: none;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20rem;
  width: 100%;
}
.spinnerContainer svg {
  font-size: 3rem;
}
