.advancedSearch {
  width: 15.6rem;
}

.advancedSearch form,
.advancedSearch input {
  background-color: var(--colorSecondary);
  color: var(--colorFont);
  display: flex;
  align-items: center;
  padding: 0.15rem;
}

.advancedSearch svg {
  max-height: calc(0.375rem + var(--fontStandard));
  height: 2.25rem;
  width: 2.25rem;
  cursor: pointer;
}

.advancedSearch input,
.advancedSearch input:focus {
  border: none;
  outline: none;
}

.advancedSearch p:focus,
.advancedSearch .focused {
  background-color: var(--colorSecondary);
}

.advancedSearch h1 {
  background-color: var(--colorDisabled);
  margin: 0;
  font-size: var(--fontMedium);
  padding: 0.25rem;
}

.advancedSearch p {
  margin: 0;
  font-size: var(--fontStandard);
  padding: 0.25rem;
}

.advancedSearch p:hover {
  background-color: var(--colorSecondary);
  cursor: pointer;
}

.advancedSearch > form {
  position: relative;
}

.displayX,
.hiddenX {
  color: var(--colorFont);
}

.hiddenX {
  opacity: 0;
}

.advancedSearchResults {
  background-color: var(--colorPrimary);
  border: 1px solid var(--colorSecondaryHover);
  position: absolute;
  top: 2.3rem;
  min-height: 0.75rem;
  max-height: 600px;
  width: 15.5rem;
  overflow-y: auto;
}

.advancedSearchResults span {
  color: var(--colorCTA);
  font-size: var(--fontStandard);
  padding-left: 0.25rem;
  font-style: italic;
}

.customSearch {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: var(--colorSecondaryHover);
}

.customSearch:has(> *:first-child) {
  border-bottom: 0.0625rem solid var(--colorSecondaryHover);
}

.customSearch form {
  background-color: var(--colorSecondaryHover);
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  position: sticky;
}

.customSearch section {
  display: flex;
  flex-wrap: wrap;
}

.customSearch label {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.customSearchWithoutForm {
  background-color: var(--colorSecondaryHover);
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.formHolder {
  padding: 0.5rem;
}

.submitHolder {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.customerStats {
  display: flex;
  gap: 1rem;
}

.customerStats > h3 {
  margin-top: 0;
}

.separateRow {
  flex-basis: 100%;
  display: flex;
  gap: 0.5rem;
}

.dateRange {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.2rem;
  align-items: center;
  width: 16.975rem;
}

.dateRange input {
  width: Calc(100% - 0.95rem);
  height: 1rem;
}

.dateRange span {
  margin-bottom: 0.2rem;
}
