.resizable {
  cursor: grab;
}

.resizing {
  cursor: col-resize;
}

.resizable span,
.resizing span {
  cursor: pointer;

  /* Prevent Highlight */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tableHolder > table {
  width: 100%;
  border-collapse: collapse;
}

.tableHolder:not(:first-child) thead {
  position: sticky;
  background: var(--colorPrimary);
}

.tableHolder tr {
  border-bottom: 0.0625rem solid var(--colorSecondary);
}

.tableHolder tbody tr:nth-child(2n) td {
  background-color: var(--colorSecondarySubtle);
}

.tableHolder tbody tr:hover {
  background-color: var(--colorSecondary);
}

.tableHolder tbody tr:hover div:has(input),
.tableHolder tbody tr:hover div:has(input) input {
  background-color: var(--colorSecondaryHover);
}

.tableHolder th,
.tableHolder td {
  height: 1rem;
}

.tableHolder th {
  font-weight: normal;
  font-size: var(--fontStandard);
  text-align: left;
  padding: 0 0.3rem;
  background-color: var(--colorCTAAccent);
  border: 0.0625rem solid var(--colorSecondary);
}

.tableHolder td {
  padding: 0.3rem;
  font-size: var(--fontStandard);
  border: 0.0625rem solid var(--colorSecondary);
}

.title {
  display: flex;
  flex-direction: row;
  font-size: var(--fontMedium);
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  height: 20px;
}

.compactView td {
  padding: 0 0.2rem;
}

.link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.clickable:hover {
  cursor: pointer;
  text-decoration: underline;
}

.deleteIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
}

.totalRow {
  font-weight: bold;
  border-top: 2px solid rgb(160, 160, 160);
}

.stickyTableHead {
  position: sticky;
  top: var(--headerHeight);
}

.textRightAlign {
  text-align: right;
}
