
.tableContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 0.3rem;
}

.tableContainer > table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0.5rem;
}

.tableContainer:not(:first-child) thead {
  position: sticky;
  background: var(--colorPrimary);
}

.tableContainer tr {
  border-bottom: 0.0625rem solid var(--colorSecondary);
}

.tableContainer tbody tr:nth-child(2n) td {
  background-color: var(--colorSecondarySubtle);
}

.tableContainer th,
.tableContainer td {
  height: 1rem;
}

.tableContainer th {
  font-weight: normal;
  font-size: var(--fontStandard);
  text-align: left;
  padding: 0 0.3rem;
  background-color: var(--colorCTAAccent);
  border: 0.0625rem solid var(--colorSecondary);
}

.tableContainer td {
  padding: 0.3rem;
  font-size: var(--fontStandard);
  border: 0.0625rem solid var(--colorSecondary);
}

.tableContainer span {
  display: inline-block;
  align-items: center;
  cursor: pointer;
}

.tableContainer div {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

/* Select */
.tableContainer th:nth-child(1) {
  width: 3%;
}

/* Load # */
.tableContainer th:nth-child(2) {
  width: 5%;
}

/* Reference */
.tableContainer th:nth-child(3) {
  width: 12%;
}

/* Status */
.tableContainer th:nth-child(4) {
  width: 7%;
}

/* Customer */
.tableContainer th:nth-child(5) {
  width: 10%;
}

/* Pickup */
.tableContainer th:nth-child(6) {
  width: 15%;
}

/* Drop Off */
.tableContainer th:nth-child(7) {
  width: 15%;
}

/* Delivered Date */
.tableContainer th:nth-child(8) {
  width: 7%;
}

/* Load Mileage */
.tableContainer th:nth-child(9) {
  width: 7%;
}

/* Total Payroll */
.tableContainer th:nth-child(10) {
  width: 7%;
}

/* Remaining Payroll */
.tableContainer th:nth-child(11) {
  width: 7%;
}

/* Apply To Balance */
.tableContainer th:nth-child(12) {
  width: 9%;
}

.applyPaymentPayrollSearch{
  column-gap: .5rem
}

.applyPaymentPayrollSearch > button {
  background-color: var(--colorGood);
  padding:0;
  margin: 0;
  height: 80%;
}

.applyPaymentPayrollSearch > button:hover {
  background-color: var(--colorGood);
}

.applyPayrollsSection {
  display: flex;
  max-width: 50%;
  min-width: 35rem;
}

.applyPayrollsSection div {
  width: 50%;
}

.applyPayrollsSection input,
.applyPayrollsSection select,
.applyPayrollsSection textarea {
  background-color: var(--colorSecondary);
}

.applyPayrollsSection input:read-only {
  background-color: #a7a7a7;
}

.applyPayrollsSection button {
  max-width: 7.5rem;
  margin-top: 0.7rem;
}