.paymentSummary {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.paymentSummaryHeader {
  display: grid;
  grid-template-columns: auto 1fr auto 1fr;
  row-gap: 0.2rem;
  margin-top: 1rem;
  font-size: var(--fontMedium);
}

.boldText {
  font-weight: bold;
  margin-right: 0.3rem;
}

/* Load Id */
.paymentSummary table td:nth-child(1) {
  width: 15%;
}

/* Amount */
.paymentSummary table td:nth-child(2) {
  width: 15%;
}

/* Balance After Payment */
.paymentSummary table td:nth-child(3) {
  width: 15%;
}

/* Days To Pay */
.paymentSummary table td:nth-child(4) {
  width: 15%;
}
