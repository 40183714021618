.title {
  border-bottom: 0.0625rem solid var(--colorFont);
  padding: 0 0 0 2.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title h1 {
  margin: 0;
  display: flex;
  gap: 2rem;
  align-items: center;
}

.title p {
  border: 2px solid var(--colorCTA);
  background-color: var(--colorCTA);
  color: black;
  font-size: 0.8rem;
  margin: 0;
  padding: 0.1rem 0.2rem 0.1rem;
  font-size: var(--fontStandard);
}

.title p:empty {
  display: none;
}

.title svg {
  font-size: 2rem;
  color: var(--colorFont);
}

.navigation {
  border-bottom: 0.0625rem solid var(--colorSecondaryHover);
  padding-left: 2.5rem;
  overflow: auto;
  display: flex;
}

.navigation button {
  margin-right: 1rem;
  padding: 1rem;
  border: none;
  background: none;
}

.navigation button:hover {
  cursor: pointer;
  text-decoration: underline;
}

.content {
  padding: 0rem 1rem 1rem 2.5rem;
  overflow: auto;
  font-size: var(--fontStandard);
}

.content.content tr {
  background: var(--colorSecondary);
  border-bottom: 0.0625rem solid var(--colorSecondaryHover);
}

.content.content td,
.content.content th {
  border: 0.0625rem solid var(--colorSecondaryHover);
}
