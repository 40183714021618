.title {
  display: block;
  font-size: var(--fontMedium);
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.tableContainer table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0.5rem;
}

.tableContainer:not(:first-child) thead {
  position: sticky;
  background: var(--colorPrimary);
}

.tableContainer tr {
  border-bottom: 0.0625rem solid var(--colorSecondary);
}

.tableContainer tbody tr:nth-child(2n) td {
  background-color: var(--colorSecondarySubtle);
}

.tableContainer th,
.tableContainer td {
  height: 1rem;
}

.tableContainer th {
  font-weight: normal;
  font-size: var(--fontStandard);
  text-align: left;
  padding: 0 0.3rem;
  background-color: var(--colorCTAAccent);
  border: 0.0625rem solid var(--colorSecondary);
}

.tableContainer td {
  padding: 0.3rem;
  font-size: var(--fontStandard);
  border: 0.0625rem solid var(--colorSecondary);
}

.tableContainer div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3rem;
}

/* Status */
.tableContainer th:nth-child(1) {
  width: 3%;
}

/* Type */
.tableContainer th:nth-child(2) {
  width: 15%;
}

/* Rate */
.tableContainer th:nth-child(3) {
  width: 8%;
}

/* Quantity */
.tableContainer th:nth-child(4) {
  width: 8%;
}

/* Total */
.tableContainer th:nth-child(5) {
  width: 5%;
}

/* Note */
.tableContainer th:nth-child(6) {
  width: 35%;
}

/* Delete */
.tableContainer th:nth-child(7) {
  width: 2%;
}

.tableContainer th:nth-child(7) td:hover {
  cursor: pointer;
}

.tableContainer input {
  width: calc(100% - 0.6rem);
}

.tableContainer select {
  width: 100%;
}

.draft.draft {
  background-color: var(--colorCTAAccent);
}

.addButton,
.deleteButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
}
