.statusBackgroundColor[data-attributesecondaryhidden^='Needs Load Information'] {
  background-color: var(--needLoadInfo) !important;
  color: black;
}

.statusBackgroundColor[data-attributesecondaryhidden^='Needs Attention'] {
  background-color: var(--needsAttention) !important;
}

.statusBackgroundColor[data-attributesecondaryhidden^='Waiting On Paperwork'] {
  background-color: var(--waitingOnPaperwork) !important;
}

.statusBackgroundColor[data-attributesecondaryhidden^='Sent To Claims'],
.statusBackgroundColor[data-attributesecondaryhidden^='Claim In Process'],
.statusBackgroundColor[data-attributesecondaryhidden^='Claim Resolved'] {
  background-color: var(--sentToClaims) !important;
}

.statusBackgroundColor[data-attributesecondaryhidden^='Ready To Load'],
.statusBackgroundColor[data-attributesecondaryhidden^='On The Road'] {
  background-color: var(--readyToLoad) !important;
  color: white;
}

.statusBackgroundColor[data-attributesecondaryhidden^='Delivered'] {
  background-color: var(--delivered) !important;
  color: white !important;
}


.dailyStatusBackgroundColor[data-attributetertiaryhidden^='Needs Call'] {
  background-color: var(--needsCall) !important;
}

.statusBackgroundColor[data-attributesecondaryhidden=''] {
  background-color: var(--noDriver) !important;
  color: black;
}

.dailyStatusBackgroundColor[data-attributetertiaryhidden^='Dispatched'] {
  background-color: var(--dispatched) !important;
  color: black;
}

.dailyStatusBackgroundColor[data-attributetertiaryhidden^='Check Call'] {
  background-color: var(--checkCall) !important;
  color: black;
}

.dailyStatusBackgroundColor[data-attributetertiaryhidden^='No Contact'] {
  background-color: var(--noContact) !important;
}

.dailyStatusBackgroundColor[data-attributetertiaryhidden^='Issue'] {
  background-color: var(--issue) !important;
}

.dailyStatusBackgroundColor[data-attributetertiaryhidden^='Night Watch'] {
  background-color: var(--nightWatch) !important;
}

.completedMilesBreakdown {
  margin-top: 0.9rem;
  width: 9rem;
}

.completedMilesBreakdown span {
  display: flex;
  justify-content: space-between;
  border: none;
}

.completedMilesBreakdown :nth-child(1),
.completedMilesBreakdown :nth-child(3) {
  border: none;
}

.completedMilesBreakdown span:nth-child(2) {
  padding-bottom: 0.1rem;
  border-bottom: 0.0625rem solid var(--colorSecondaryHover);
}

.completedMilesBreakdown > tr :nth-child(1) {
  font-weight: bold;
}

.branchSelectOptions {
  display: inline-block;
  position: absolute;
  width: 15rem;
  background: var(--colorSecondary);
  box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
}

.branchSelectOptions label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.branchSelectOptions span {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.branchSelectOptions span:hover,
.selected {
  background-color: var(--colorSecondaryHover);
}

.colorLegend {
  position: absolute;
  background: var(--colorSecondary);
  box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
  font-size: var(--fontMedium);
  white-space: nowrap;
  padding-bottom: 0.25rem;
}

.colorLegend > h4 {
  color: var(--colorFont);
  background-color: var(--colorSecondaryHover);
  font-size: var(--fontMedium);
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 0.25rem 0;
}

.colorLegend > div {
  padding: 0 0.5rem;
}

.colorLegend > div > h6 {
  font-size: var(--fontStandard);
  font-weight: bold;
  border-bottom: 1px solid var(--colorOverlay);
  margin: 0.25rem 0;
}

.colorLegend > div > span {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.1rem;
}

.needsLoadInfo {
  color: var(--needLoadInfo);
}

.needsAttention {
  color: var(--needsAttention);
}

.readyToLoad {
  color: var(--readyToLoad);
}

.delivered {
  color: var(--delivered);
}

.noDriver {
  color: var(--noDriver);
}

.needsCall {
  color: var(--needsCall);
}

.dispatched {
  color: var(--dispatched);
}

.checkCall {
  color: var(--checkCall);
}

.issue {
  color: var(--issue);
}

.nightWatch {
  color: var(--nightWatch);
}
