.title {
  display: block;
  font-size: var(--fontMedium);
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.tableContainer > table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0.5rem;
}

.tableContainer:not(:first-child) thead {
  position: sticky;
  background: var(--colorPrimary);
}

.tableContainer tr {
  border-bottom: 0.0625rem solid var(--colorSecondary);
}

.tableContainer tbody tr:nth-child(2n) td {
  background-color: var(--colorSecondarySubtle);
}

.tableContainer th,
.tableContainer td {
  height: 1rem;
  width: 5rem;
}

.tableContainer th {
  font-weight: normal;
  font-size: var(--fontStandard);
  text-align: left;
  padding: 0 0.3rem;
  background-color: var(--colorCTAAccent);
  border: 0.0625rem solid var(--colorSecondary);
}

.tableContainer td {
  padding: 0.3rem;
  font-size: var(--fontStandard);
  border: 0.0625rem solid var(--colorSecondary);
}

.tableContainer span {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tableContainer div {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.tableContainer select {
  width: 100%;
}

.buttonContainer {
  margin: 0.5rem 0 0 1.5rem;
  display: flex;
  align-items: center;
}

.buttonContainer button {
  margin: 0;
  width: max-content;
}

.paymentSummary {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.paymentSummary h3 {
  display: flex;
  justify-content: space-evenly;
}

/* Load Id */
.paymentSummary table td:nth-child(1) {
  width: 15%;
}

/* Amount */
.paymentSummary table td:nth-child(2) {
  width: 15%;
}

/* Balance After Payment */
.paymentSummary table td:nth-child(3) {
  width: 15%;
}

/* Days To Pay */
.paymentSummary table td:nth-child(4) {
  width: 15%;
}

.paymentSummaryHeader {
  display: grid;
  grid-template-columns: auto 1fr auto 1fr;
  row-gap: 0.2rem;
  margin-top: 1rem;
  font-size: var(--fontMedium);
}

.boldText {
  font-weight: bold;
  margin-right: 0.3rem;
}
