.error {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 35rem;
  height: 20rem;
  max-width: 100vw;
  box-shadow: rgb(0 0 0 / 60%) 0 1.6rem 2.5rem 0,
    rgb(0 0 0 / 50%) 0 0.3rem 0.9rem 0;
  background-color: var(--colorSecondary);
  animation: fadeInSolid 0.01s ease-in;
}

.title {
  margin: .5rem 0 0 0;
}

.warning {
  font-weight: bold;
  color: var(--colorBad);
}

.buttons {
  display: flex;
  justify-content: space-around;
  margin-top: .5rem;
  width: 100%;
}

.error p {
  margin: 0 0.5rem 0;
  text-align: center;
}

.error form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error form select {
  width: 30%;
}

.error form select,
.error form input {
  margin: 0 0 0.5rem 0;
}

.error form label {
  margin-bottom: 0.3rem;
}

@keyframes fadeInSolid {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
