.overlay {
  display: none;
}

.panelContainer:has(.panelHolder > *:first-child) .overlay {
  display: block;
  position: fixed;
  top: 3.125rem;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--colorOverlay);
  overflow: hidden;
  animation: fadeIn 0.25s linear;
  z-index: 2;
}

.panelHolder > * {
  display: block;
  position: fixed;
  top: 3.125rem;
  right: 0;
  bottom: 0;
  background-color: var(--colorSecondary);
  transition: transform 0.25s linear;
  animation: slideIn 0.25s linear;
  z-index: 10;
}

.panel {
  top: 0;
  bottom: 0;
  width: 93.25rem;
  max-width: 100vw;
  right: 0;
  position: absolute;
  display: grid;
  grid-template-columns: 29.0625rem auto;
  grid-template-rows: 2.75rem auto 4.125rem;
  grid-template-areas: 'title title' 'navigation contentHolder' 'spacer buttonRow';
  box-shadow: rgb(0 0 0 / 60%) 0 1.6rem 2.5rem 0,
    rgb(0 0 0 / 50%) 0 0.3rem 0.9rem 0;
  background-color: var(--colorSecondary);
  animation: slideIn 0.25s ease-out;
}

.panel:first-child {
  box-shadow: rgb(0 0 0 / 60%) 0 1.6rem 2.5rem 0,
    rgb(0 0 0 / 50%) 0 0.3rem 0.9rem 0;
}

.title {
  border-bottom: 0.0625rem solid var(--colorFont);
  padding: 0 0 0 2.75rem;
  grid-area: title;
  display: flex;
  align-items: center;
}

.title h1 {
  margin: 0;
}

.navigation {
  border-right: 0.0625rem solid var(--colorSecondaryHover);
  padding: 3rem 0 3rem 2.5rem;
  grid-area: navigation;
  overflow: auto;
}

.navigation td {
  min-width: 3rem;
  height: 3.5rem;
}

.content {
  padding: 1rem 2rem 3rem 3rem;
  grid-area: contentHolder;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.content ul {
  margin-top: 0;
  list-style-type: none;
  padding-left: 1rem;
}

.content li > *:first-child {
  margin-right: 0.5rem;
}

.content.content table thead {
  background: var(--colorSecondary);
  border-bottom: 0.0625rem solid var(--colorSecondaryHover);
  top: -1rem;
}

.footer,
.footerSpacer {
  display: flex;
  flex-direction: row;
  border-top: 0.0625rem solid var(--colorFont);
  grid-area: buttonRow;
}

.footerSpacer {
  grid-area: spacer;
}

.footer {
  padding: 1rem 3rem 0;
  display: flex;
  justify-content: space-between;
}

.navBtns {
  display: flex;
}

.navBtns button:not(:first-child) {
  margin-left: 1.25rem;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: var(--colorOverlay);
  }
}
