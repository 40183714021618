.iconHolder {
  margin-right: .5rem;
}

.profileIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  box-shadow: 0 .0625rem .1875rem rgba(0,0,0,0.2);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.profileIcon:hover {
  background-color: #f5f5f5;
}

.menu {
  position: absolute;
  right: 0;
  min-width: 22.625rem;
  padding-left: 1.15rem;
  background-color:  var(--colorPrimary);
  border-left: 0.125rem solid var(--colorProfileBorder);
  border-bottom: 0.125rem solid var(--colorProfileBorder);
  box-shadow: 0 .125rem .625rem rgba(0,0,0,0.2);
  transition: opacity 0.2s ease, transform 0.2s ease;
  transform: translateY(.5rem);
}

.menu.open {
  transform: translateY(0);
  opacity: 0.9;
}