.reports {
    display: flex;
    flex-direction: column;
}

.reports > a {
    margin-top: .5rem;
    margin-left: .5rem;
}

.reports > h3 {
    margin-bottom: 0;
}