.contentHolder {
  max-height: calc(100vh - 11.3125rem);
  overflow: auto;
}

.attributeGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.pairedAttributes {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.pairedAttributes:first-child {
  margin-top: 1rem;
}

.pairedAttributes > *:not(:first-child) {
  margin-left: 2rem;
}

.pairedAttributes .attribute:has(textarea),
.pairedAttributes .attribute:has(p) {
  width: 100%;
}

.pairedAttributes:has(button) {
  display: flex;
  justify-content: space-between;
}

.assignment {
  width: 100%;
}

.attribute {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: unset;
  max-width: calc(50vw - 4rem);
  padding-bottom: 0.5rem;
  word-wrap: break-word;
}

.attributeLabel {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: unset;
  font-size: var(--fontMedium);
  max-width: calc(50vw - 4rem);
  padding-bottom: 1rem;
  word-wrap: break-word;
}

.bad {
  font-weight: bold;
  color: var(--colorBad);
}
.bad:hover {
  text-decoration: underline;
  cursor: pointer;
}
.pairedButtons {
  display: flex;
  align-items: center;
}

.pairedButtons div {
  margin-right: 1rem;
}
.pdfContainer {
  margin-top: 1rem;
  height: 150vh;
}
.pdfContainer iframe {
  width: 100%;
  height: 100%;
}

.attribute:has(input),
.attribute:has(select) {
  width: 17.125rem;
}

.attribute > label {
  margin-bottom: 0.2rem;
}

.attribute > label svg {
  margin-left: 0.2rem;
  vertical-align: middle;
}

.attribute h3,
.attribute h4 {
  margin-bottom: 0.5rem;
}

.attribute p {
  margin: 0;
}

.cTA {
  color: var(--colorCTA);
  cursor: pointer;
  font-size: var(--fontStandard);
}

.clickable:hover {
  cursor: pointer;
  text-decoration: underline;
}

.center {
  width: 100%;
  vertical-align: -1.5px;
}

.cTA:hover {
  text-decoration: underline;
}

.iconCTA {
  color: var(--colorCTA);
  cursor: pointer;
}

.attribute .cTA {
  margin-top: 0.1rem;
}

.dropdown {
  position: relative;
  height: 100%;
}

.dropdown button {
  padding: 0 0.5rem;
}

.dropdownOptions {
  position: absolute;
  background-color: var(--colorSecondary);
  box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px,
    rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
}

.dropdownOptions div {
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 2rem 2rem 10rem;
}

.dropdownOptions div:hover {
  background-color: var(--colorSecondaryHover);
}

.flex {
  display: flex;
}

.flexAlignCenterJustifyCenter {
  display: flex;
  align-items: center;
}

.flexAlignCenterJustifyLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flexAlignCenterJustifyRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  padding: 0.2rem;
  margin-bottom: 0.5rem;
  gap: 0.4rem;
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h1:has(input) {
  display: flex;
  justify-content: space-between;
}

.viewGrid {
  padding-top: 0.3rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.span2 {
  grid-column: span 2;
}

.span3 {
  grid-column: span 3;
}

.spanVert2 {
  grid-row: span 2;
}

.splitRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerWithoutMargin {
  margin: 0;
}

.marginTop05Rem {
  margin-top: 0.5rem;
}

.marginTop1Rem {
  margin-top: 1.1rem;
}

.grayBottomDivider {
  border-bottom: 2px solid var(--colorSecondaryHover);
}

.required {
  color: var(--colorBad);
}

.hiddenToggler {
  display: none;
}

.error {
  color: var(--colorBad);
  font-weight: bold;
}

.toggleDisplayHolder {
  display: flex;
  height: 100%;
  border-right: 0.125rem solid var(--colorSecondary);
  border-left: 0.125rem solid var(--colorSecondary);
}

.toggleDisplayHolder button {
  padding-right: 0.75rem;
}

.buttonActive.buttonActive {
  background-color: var(--colorSecondaryHover);
}

.stickyBelowHeader {
  position: sticky;
  top: 2rem;
}

.sideBySideButtons {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

/* keeps subheaders from overriding stickyness of primary headers */
header:not(:first-of-type) {
  position: relative;
  z-index: 0;
}

/* if a header component has a sibling BBI table component add margin RIP marginBottom05Rem */
header:has(+ div > table) {
  margin-bottom: 0.5rem;
}

.inputNote {
  font-size: var(--fontStandard);
  color: red;
}

.flexSpaceBetween > button {
  margin: 0;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
}

.spinnerContainer > svg {
  width: 6rem;
  height: 6rem;
}
