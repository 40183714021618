.title {
  display: block;
  font-size: var(--fontMedium);
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 0.3rem;
}

.tableContainer > table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0.5rem;
}

.tableContainer:not(:first-child) thead {
  position: sticky;
  background: var(--colorPrimary);
}

.tableContainer tr {
  border-bottom: 0.0625rem solid var(--colorSecondary);
}

.tableContainer tbody tr:nth-child(2n) td {
  background-color: var(--colorSecondarySubtle);
}

.tableContainer th,
.tableContainer td {
  height: 1rem;
}

.tableContainer th {
  font-weight: normal;
  font-size: var(--fontStandard);
  text-align: left;
  padding: 0 0.3rem;
  background-color: var(--colorCTAAccent);
  border: 0.0625rem solid var(--colorSecondary);
}

.tableContainer td {
  padding: 0.3rem;
  font-size: var(--fontStandard);
  border: 0.0625rem solid var(--colorSecondary);
}

.tableContainer span {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tableContainer div {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.applyPaymentSection {
  display: flex;
  margin-bottom: 0.05rem;
}

.applyPaymentSection div {
  width: 50%;
}

.applyPaymentSection input,
.applyPaymentSection select,
.applyPaymentSection textarea {
  background-color: var(--colorSecondary);
}

.applyPaymentSection input:read-only {
  background-color: #a7a7a7;
}

/* Select */
.tableContainer th:nth-child(1) {
  width: 5%;
}

/* Load # */
.tableContainer th:nth-child(2) {
  width: 5%;
}

/* Reference */
.tableContainer th:nth-child(3) {
  width: 20%;
}

/* QuickBooks Id */
.tableContainer th:nth-child(4) {
  width: 10%;
}

/* Invoice Date */
.tableContainer th:nth-child(5) {
  width: 10%;
}

/* Due Date */
.tableContainer th:nth-child(6) {
  width: 10%;
}

/* Invoice Total */
.tableContainer th:nth-child(7) {
  width: 12.5%;
}

/* Balance */
.tableContainer th:nth-child(8) {
  width: 12.5%;
}

/* Apply To Balance */
.tableContainer th:nth-child(9) {
  width: 15%;
}

.draft.draft {
  background-color: var(--colorCTAAccent);
}

.buttonContainer {
  margin: 0.5rem 0 0 1.5rem;
  display: flex;
  align-items: center;
}

.buttonContainer button {
  margin: 0;
  width: max-content;
}

.applyPaymentButton {
  margin: 0.5rem 0 0.5rem;
  padding: 0 1rem;
  background-color: var(--colorGood);
  border: none;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 4rem;
  color: white;
  font-weight: bold;
  font-size: var(--fontStandard);
}
