
*:has(.createCheckCallHolder) .btnHolder {
  border-bottom: 0.0625rem solid var(--colorSecondary);
}

*:has(.createCheckCallHolder:empty) .btnHolder {
  border-bottom: 0.0625rem solid var(--colorSecondaryHover);
}

.btnHolder {
  background-color: var(--colorSecondary);
  z-index: 4;
  display: flex;
  top: 0;
}

.btnHolder svg {
  padding: 0 0.25rem;
}

.btnHolder button {
  padding: 0.5rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  height: inherit;
  display: flex;
  align-items: center;
}

.createCheckCallHolder {
  z-index: 3;
  background-color: var(--colorSecondary);

}

.createCheckCallHolder button svg {
  margin: 0 0.25rem;
  height: 1rem;
  width: 1rem;
}

.createCheckCallHolder {
  transition: height ease 0.5s;
  height: 0;
  overflow: hidden;
}

.createCheckCallHolder:has(> *:first-child) {
  height: 16.5rem;
  border-bottom: 0.0625rem solid var(--colorSecondaryHover);
}

.stopOrderArrowButton {
  font-size: 2rem;
  border: none;
  background-color: transparent;
  margin-left: -1.8rem;
  height: 2.5rem;
  width: 2.5rem;
  align-self: center;
}

.stopOrderArrowButton:hover {
  cursor: pointer;
}

.stopOrderArrowButton > svg {
  pointer-events: none;
}

.stopOrderArrowButton > svg.enabled {
  color: var(--colorCTA);
}

.stopOrderArrowButton > svg.disabled {
  color: lightgrey;
}

.stopOrderArrowButton > svg.disabled:hover {
  cursor: unset;
}