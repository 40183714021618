.overlay {
  display: none;
}

.errorContainer:has(.errorHolder > *:first-child) .overlay {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--colorOverlay);
  overflow: hidden;
  animation: fadeIn 0.25s linear;
  z-index: 13;
}

.errorHolder > * {
  width: fit-content;
  height: fit-content;
  position: fixed;
  top: calc(50% - 15rem / 2);
  left: calc(50% - 35rem / 2);
  animation: fadeInSolid 0.01s ease-in;
  z-index: 14;
}

.error {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  justify-content: space-around;
  align-items: center;
  width: 35rem;
  height: fit-content;
  min-height: 15rem;
  max-height: 25rem;
  max-width: 100vw;
  box-shadow: rgb(0 0 0 / 60%) 0 1.6rem 2.5rem 0,
    rgb(0 0 0 / 50%) 0 0.3rem 0.9rem 0;
  background-color: var(--colorSecondary);
  animation: fadeInSolid 0.01s ease-in;
}

.error h3 {
  text-align: center;
  overflow-y: auto;
  /* Pre-line will break the line when coming across newline characters, such as \n, which 
    allows us to have some control over formatting without sending HTML from the backend */
  white-space: pre-line;
}

.error button {
  width: 75%;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: var(--colorOverlay);
  }
}

@keyframes fadeInSolid {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.yesNoModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35rem;
  height: 15rem;
  max-width: 100vw;
  box-shadow: rgb(0 0 0 / 60%) 0 1.6rem 2.5rem 0,
    rgb(0 0 0 / 50%) 0 0.3rem 0.9rem 0;
  background-color: var(--colorSecondary);
  animation: fadeInSolid 0.01s ease-in;
  justify-content: space-around;
}

.yesNoModal h3 {
  width: 90%;
  text-align: center;
  font-size: 1.25rem;
}

.buttonHolder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3.5rem;
}
