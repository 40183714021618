.mapContainer {
  position: relative;
  min-height: 20rem;
}

.latestLocationSection {
  margin-bottom: 1rem;
}

.latestLocationAddressIcon {
  width: 100%;
  display: flex;
  align-items: center;
  gap: .5rem;
}

.latestLocationAddressIcon img {
  height: 1rem;
  width: 1rem;
}
