.filterBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--colorSecondary);
  padding: 0.25rem;
  height: 1rem;
  width: 15.8125rem;
}

.filterBar svg {
  max-height: calc(0.375rem + var(--fontStandard));
  height: 2.25rem;
  width: 2.25rem;
}

.displayX,
.hiddenX {
  color: var(--colorFont);
}

.hiddenX {
  opacity: 0;
}

.filterBar input,
.filterBar input:focus {
  border: none;
  background-color: var(--colorSecondary);
  outline: none;
  width: 100%;
  color: var(--colorFont);
  height: .90125rem;
}

.filterBar input:first-child {
  margin-left: 0.5rem;
}

.filterBar input::-webkit-outer-spin-button,
.filterBar input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
