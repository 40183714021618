.good {
  color: green;
}

.bad {
  color: red;
}

.title {
  border-bottom: 0.125rem solid var(--colorSecondary);
  padding-bottom: 1rem;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 1rem;
  width: 15rem;
}

.contentContainer > h5 {
    margin: 0;
    text-align: center;
}

.buttonContainer {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  width: 15rem;
}

.statusContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}

.statusContainer svg {
  height: 4rem;
  width: 4rem;
}

.message {
    color: tomato;
    text-align: center;
    margin: 0;
}

