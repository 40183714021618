.trailerCostsContainer {
  display: grid;
  grid-template-areas: 'overlap';
  place-content: center;
  overflow: auto;
}

.trailerInputContainer,
.trailerDiagramContainer {
  grid-area: overlap;
}

.trailerInputContainer {
  padding-top: 4rem;
  display: grid;
  grid-template-areas:
    'fadso . fadsi fapsi . fapso'
    'radso . radsi rapsi . rapso';
  grid-template-columns: min-content 8.2rem min-content min-content 8.2rem min-content;
  gap: 1rem;
  row-gap: 1rem;
  z-index: 2;
}

.trailerInputContainer input {
  box-sizing: border-box;
  max-width: 6rem;
}

.trailerInputContainer div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.trailerInputContainer label {
  text-align: center;
}

.trailerDiagramContainer {
  display: flex;
  justify-content: center;
}

.trailerInputContainer > svg {
  height: 60rem;
}

.trailerInputContainer .fadso {
  grid-area: fadso;
}

.trailerInputContainer .fadsi {
  grid-area: fadsi;
}

.trailerInputContainer .fapsi {
  grid-area: fapsi;
}

.trailerInputContainer .fapso {
  grid-area: fapso;
}

.trailerInputContainer .radsi {
  grid-area: radsi;
  flex-direction: column-reverse;
}

.trailerInputContainer .radso {
  grid-area: radso;
  flex-direction: column-reverse;
}

.trailerInputContainer .rapsi {
  grid-area: rapsi;
  flex-direction: column-reverse;
}

.trailerInputContainer .rapso {
  grid-area: rapso;
  flex-direction: column-reverse;
}

/* Truck */

.truckCostsContainer {
  display: grid;
  grid-template-areas: 'overlap';
  place-content: center;
  overflow: auto;
}

.truckInputContainer,
.truckDiagramContainer {
  grid-area: overlap;
}

.truckInputContainer {
  display: grid;
  grid-template-areas:
    '. . . . .'
    '. dss . pss .'
    '. . . . .'
    'fadso fadsi . fapsi fapso'
    'radso radsi . rapsi rapso';
  grid-template-columns: min-content min-content 18rem min-content min-content;
  grid-template-rows: 4rem auto 20rem auto auto;
  z-index: 2;
  gap: 1rem;
  justify-content: center;
  padding-right: .6rem;
}

.truckInputContainer input {
  box-sizing: border-box;
  max-width: 6rem;
}

.truckInputContainer div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
}

.truckInputContainer label {
  text-align: center;
}

.truckDiagramContainer {
  display: flex;
  justify-content: center;
}

.truckDiagramContainer > svg {
  max-height: 50rem;
}

.truckInputContainer .dss {
  grid-area: dss;
}

.truckInputContainer .pss {
  grid-area: pss;
}

.truckInputContainer .fadsi {
  grid-area: fadsi;
}

.truckInputContainer .fadso {
  grid-area: fadso;
}

.truckInputContainer .fapsi {
  grid-area: fapsi;
}

.truckInputContainer .fapso {
  grid-area: fapso;
}

.truckInputContainer .radsi {
  grid-area: radsi;
  flex-direction: column-reverse;
  justify-content: start;
}

.truckInputContainer .radso {
  grid-area: radso;
  flex-direction: column-reverse;
  justify-content: start;
}

.truckInputContainer .rapsi {
  grid-area: rapsi;
  flex-direction: column-reverse;
  justify-content: start;
}

.truckInputContainer .rapso {
  grid-area: rapso;
  flex-direction: column-reverse;
  justify-content: start;
}
