.item {
  display: grid;
  grid-template-rows: max-content 0fr;
  transition: grid-template-rows 0.5s ease;
}

.item.active {
  grid-template-rows: max-content 1fr;
}

.title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.title svg {
  font-size: 1.25rem;
}

.content {
  overflow: hidden;
}

.icon {
  display: flex;
  max-width: fit-content;
  max-height: fit-content;
  transition: transform 0.3s ease;
}

.isActive {
  transform: rotate(180deg);
}
