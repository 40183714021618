.container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 1rem;
}

.container input {
  width: 2rem;
  color: var(--colorFont);
  background-color: var(--colorPrimary);
  border-color: var(--colorSecondary);
  padding: 0.5rem;
  border-width: 0.125rem;
  border-style: inset;
}

.container button {
    color: white;
    font-weight: bold;
    background-color: var(--colorGood);
    padding: 0 1rem;
    border: none;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 5rem;
  }
  
