
.reminder {
  font-size: var(--fontMedium);
}

.emailConfirm {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.emailConfirm > input {
  max-width: 30%;
}

.emailConfirm > textarea {
  max-width: 55%;
}

.tableContainer {
  display: grid;
  grid-template-columns: repeat(3);
  text-align: left;
}

.tableContainer td,
.tableContainer th {
  border-bottom: 1px solid var(--colorSecondaryHover);
}

.statusIcon {
  font-size: var(--fontLarge)
}

.greyDot {
  color: var(--colorSecondaryHover);
}

.failure {
  color: var(--noDriver);
}