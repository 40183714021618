.container.container {
  width: unset;
}

.container > input {
  color: var(--colorFont);
  background-color: var(--colorPrimary);
  border-color: var(--colorSecondary);
  padding: 0.3rem;
  border-width: 0.125rem;
  border-style: inset;
  width: 16.125rem;
}

input.has-error {
  border-color: tomato;
}

.error {
  margin: 0;
  padding-left: 0.5rem;
  font-size: 90%;
  color: tomato;
}

.tagItem {
  background-color: var(--colorDisabled);
  border-radius: 2rem;
  height: 1.4rem;
  width: fit-content;
  padding: 0 0.4rem 0 0.8rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.deleteIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.3rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  cursor: pointer;
  color: tomato;
}

.emailHolder {
  padding-top: 0.5rem;
}
