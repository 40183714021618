.overlay {
  display: none;
}

.slabContainer:has(.slabHolder > *:first-child) .overlay {
  display: block;
  position: fixed;
  top: 3.125rem;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--colorOverlay);
  overflow: hidden;
  animation: fadeIn 0.25s linear;
  z-index: 2;
}

.slabHolder > * {
  display: block;
  position: fixed;
  top: 3.125rem;
  right: 0;
  bottom: 0;
  background-color: var(--colorSecondary);
  transition: transform 0.25s linear;
  animation: slideIn 0.25s linear;
  z-index: 10;
}

.slabHolder > *:first-child {
  box-shadow: rgb(0 0 0 / 60%) 0 1.6rem 2.5rem 0,
    rgb(0 0 0 / 50%) 0 0.3rem 0.9rem 0;
}

.slabHolder > * > * {
  height: 100%;
  width: 60rem;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 2.75rem 3rem auto;
}

.slabHolder:has(> *:nth-child(2)) > *:last-child {
  transform: translateX(0);
}

.slabHolder:has(> *:nth-child(2)) > *:nth-last-child(2) {
  transform: translateX(-100%);
}

.slabHolder:has(> *:nth-child(3)) > *:nth-last-child(n + 3) {
  transform: translateX(-200%);
}

.slabHolder:has(> *:nth-child(2)) > * {
  border-right: solid 0.0625rem var(--colorOverlay);
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: var(--colorOverlay);
  }
}
