.mapContainer {
    position: relative;
    min-height: 20rem;
    margin-top: 1.5rem;
    padding: 0rem 6rem 0rem;
  }
  
  .latestLocationSection {
    margin-bottom: 1rem;
  }
  
  .latestLocationAddressIcon {
    width: 100%;
    display: flex;
    align-items: center;
    gap: .5rem;
  }
  
  .latestLocationAddressIcon img {
    height: 1rem;
    width: 1rem;
  }

  .tableContainer {
    margin-top: 1.25rem;
    padding: 0rem 6rem 0rem;
  }

  .legend {
    display: flex;
    flex-direction: row;
    align-items: left;
    padding-left: 6.25rem;
    gap: .813rem;
  }

  .legendItem {
    display: flex;
    align-items: center;
  }

  .legend p {
    margin: 0rem;
    font-size: .9rem;
  }

  .icon {
    height: 1.5rem;
    width: 1.5rem;
  }

  .legendItem > strong {
    margin-left: .313rem;
  }
