.receivableTableContainer.receivableTableContainer {
  width: 100%;
}

.receivableTable {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.3rem;
  width: 100%;
}

.receivableTable input:disabled,
.receivableTable select:disabled {
  border: none;
}

.receivableTable > table {
  width: 100%;
  border-collapse: collapse;
}

.receivableTable:not(:first-child) thead {
  position: sticky;
  background: var(--colorPrimary);
}

.receivableTable tr {
  border-bottom: 0.0625rem solid var(--colorSecondary);
}

.receivableTable tbody tr:nth-child(2n) td {
  background-color: var(--colorSecondarySubtle);
}

.receivableTable th,
.receivableTable td {
  height: 1rem;
}

.receivableTable th {
  font-weight: normal;
  font-size: var(--fontStandard);
  text-align: left;
  padding: 0 0.3rem;
  background-color: var(--colorCTAAccent);
  border: 0.0625rem solid var(--colorSecondaryHover);
}

.receivableTable td {
  padding: 0.3rem;
  font-size: var(--fontStandard);
  border: 0.0625rem solid var(--colorSecondaryHover);
}

.receivableTable div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.groupedAttributes {
  display: flex;
  align-items: center;
}

.groupedAttributes h4 {
  width: 7rem;
}

.groupedAttributes button {
  height: fit-content;
  align-self: center;
  padding: .5rem .5rem;
  width: 8rem;
}

.addButton {
  font-weight: normal;
  font-size: var(--fontStandard);
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
}

.addButton > span {
  margin-left: 0.3rem;
}

.receivableTableContainer > div {
  display: flex;
  justify-content: space-between;
}

.tableFooter {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
}

.rentalEquipmentTable {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.3rem;

}


.rentalEquipmentTable > table {
  width: 100%;
  border-collapse: collapse;
}

.rentalEquipmentTable:not(:first-child) thead {
  position: sticky;
  background: var(--colorPrimary);
}


.rentalEquipmentTable tr {
  border-bottom: 0.0625rem solid var(--colorSecondary);
}

.rentalEquipmentTable tbody tr:nth-child(2n) td {
  background-color: var(--colorSecondarySubtle);
}

.rentalEquipmentTable th,
.rentalEquipmentTable td {
  height: 1rem;
}

.rentalEquipmentTable th {
  font-weight: normal;
  font-size: var(--fontStandard);
  text-align: left;
  padding: 0 0.3rem;
  background-color: var(--colorCTAAccent);
  border: 0.0625rem solid var(--colorSecondaryHover);
}

.rentalEquipmentTable td {
  padding: 0.3rem;
  font-size: var(--fontStandard);
  border: 0.0625rem solid var(--colorSecondaryHover);
}
