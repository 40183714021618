
*:has(.createReceiptHolder) .btnHolder {
  border-bottom: 0.0625rem solid var(--colorSecondary);
}

*:has(.createReceiptHolder:empty) .btnHolder {
  border-bottom: 0.0625rem solid var(--colorSecondaryHover);
}

.btnHolder {
  position: sticky;
  background-color: var(--colorSecondary);
  z-index: 4;
  display: flex;
  top: 0;
}

.btnHolder svg {
  padding: 0 0.25rem;
}

.btnHolder button {
  padding: 0.5rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  height: inherit;
  display: flex;
  align-items: center;
}

.createReceiptHolder {
  z-index: 3;
  background-color: var(--colorSecondary);
  transition: height ease 0.5s;
  height: 0;
  overflow: hidden;
}

.createReceiptHolder:has(> *:first-child) {
  height: 9.5rem;
  border-bottom: 0.0625rem solid var(--colorSecondaryHover);
}