.header {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  height: var(--headerHeight);
  border-top: 0.125rem solid var(--colorSecondary);
  border-bottom: 0.125rem solid var(--colorSecondary);
  background-color: var(--colorPrimary);
  z-index: 1;
}

.header button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  height: inherit;
  display: flex;
  align-items: center;
}

.header button:hover {
  background-color: var(--colorSecondaryHover);
}

.header button svg {
  margin: 0 0.25rem;
  height: 1rem;
  width: 1rem;
}

.header > * {
  height: inherit;
  display: flex;
  align-items: center;
}

.header h3 {
  margin: 0 .5rem 0;
}
