.container {
  display: flex;
  flex-direction: column;
  min-width: 25rem;
  min-height: 25rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  flex-grow: 1;
}

.title {
  margin: 0;
}

.form textarea {
  flex-grow: 1;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
}

