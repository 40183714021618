.confirm,
.btnContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.btnContainer button {
  margin: 0;
}
