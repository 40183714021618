.headerBanner {
  position: sticky;
  top: 0;
  width: 100vw;
  background-color: var(--colorHeaderBanner);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 3rem;
  border-bottom: 0.125rem solid var(--colorFont);
  color: #fff;
  z-index: 5;
}

.headerBanner > a,
.logo {
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: var(--fontLarge);
}

.headerBanner > * {
  display: flex;
  align-items: center;
}

.filterHolder {
  justify-content: center;
  margin-right: 0.75rem;
}

.profileHolder {
  gap: 1rem;
  justify-content: flex-end;
}

.profileHolder > svg:hover {
  background-color: #f5f5f5;
  transition: background-color 0.2s ease;
  border-radius: 4px;
  cursor: pointer;
}

.logo {
  height: 2.375rem;
  width: 4rem;
  margin-top: 0.125rem;
}

.title {
  color: #eee;
}

.headerBanner svg {
  width: 2.25rem;
  height: 2.25rem;
}
